.course_button_primary, .course_button_selected_primary {
    font-weight: 900;
    /* font-size: min(calc(1.4vw), 20px); */
    font-size: 1.5vw;
    width: calc(9vw);
    height: calc(3.5vw);
    min-width: 100px;
    min-height: 40px;
    /* max-width: 120px;
    max-height: 40px; */
    color: #ffffff;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 3px solid white;
    padding: 1vw;

    display: flex;           /* 將按鈕設置為 flex 容器 */
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
}

.course_button_primary {
    background-color: #0a6666;
}

.course_button_primary:hover {
    background-color: #1ec9c9;
}

.course_button_selected_primary {
    background-color: #fec104;
}

@media (max-width: 768px) {
    .course_button_primary, .course_button_selected_primary {
        width: 20vw;  /* Adjust this as per your requirements */
        height: 8vw;  /* Adjust this as per your requirements */
        /* ... other styles */
    }
}
