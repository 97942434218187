.single_video_page_container_flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transform-origin: left top;
    transform: scale(1);
}

.single_video_page_auto_grow {
    flex-grow: 1;
}

.single_video_page_logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10vw !important;
}

.main-container{
    display: flex;
    /* align-items: center; */
    padding: 0 7em;
}

.single_video_page_button_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    /* padding-top: 50px;
    padding-left: 10vw; */
}

.single_video_page_button_left > * {
    margin-bottom: 5vh;  /* 使用固定的px单位 */
}

.single_video_page_button_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120%;
    /* padding-top: 50px; */
    position: relative;  /* 使用絕對定位 */
    /* padding-right: 10vw; */
}

.single_video_page_button_right > * {
    margin-bottom: 4vh;
}

.single_video_page_button_right > *:nth-last-child(2) {
    margin-bottom: 8vh; /* 或您希望的其他值 */
}

.single_video_page_course_button {
    position: absolute;  /* 使用絕對定位 */
    bottom: 0;       /* 距離底邊的位置，您可以根據需要調整 */
    left: 50%;          /* 使元素水平居中 */
    transform: translateX(-50%);
}

.single_video_page_circle_small_button {
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.single_video_page_player_container {
    display: flex;
    align-items: center;     /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-grow: 1;            /* 允許flex容器放大 */
    overflow: hidden;        /* 確保影片不會超出容器 */
    max-width: 80%;
    margin: 0 auto;          /* 容器自身在其父元件中居中 */
    height: 100%;
}

.single_video_page_footer { /* 請使用您Footer組件的實際類名 */
    /* position: absolute; */
    /* bottom: 0; */
    width: 100%;
}

.single_video_page_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.single_video_page_loading_ring{
    /* padding-top: 10vw; */
}

@media (max-width: 768px) {
    .buttonCol {
        width: 100%;
    }

    .single_video_page_button_left, .single_video_page_button_right, .single_video_page_circle_small_button {
        flex-direction: row;   /* Make the buttons horizontal */
        justify-content: space-between;   /* Distribute buttons evenly */
        padding: 1rem 0;  /* Add some padding on top and bottom */
    }

    /* If you have specific styles for CourseButton at larger screens, reset them here */
    .single_video_page_button_left > *, .single_video_page_button_right > * {
        margin: 0;   /* Remove any margins */
    }

    .course_test_button_container {
        position: static;  /* 為了在小螢幕上正常顯示，移除絕對定位 */
        transform: none;   /* 移除 transform */
        margin-top: 1rem;  /* 設定頂部的間隙 */
    }

    .single_video_page_button_right > *:nth-last-child(2) {
        margin-bottom: 0;
    }
}

/* 新的右側活動區塊 */
.activity-section {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* height: 100%; */
    /* padding-top: 50px; */
    position: relative;
    padding-right: 10vw;
    justify-content: space-between;
}

/* 活動列表 - 可滾動，確保存錢筒固定 */
.activity-list-scrollable {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 32vh; /* 超出時啟用滾動 */
    width: 100%;
    padding-right: 8px; /* 防止滾動條擠壓內容 */
    flex: 1;
}

/* 活動卡片 */
.activity-card {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 12px;
    background-color: #ffedd5;
    transition: background 0.3s ease-in-out;
}

.activity-card.completed {
    background-color: #d1d5db;
}

/* 左側的圓形編號 */
.activity-index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 內容區塊 */
.activity-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 12px;
    text-align: left;
}

/* 時間樣式 */
.activity-time {
    font-size: 14px;
    font-weight: 600;
}

/* 活動名稱 */
.activity-name {
    font-size: 12px;
}

/* 活動圖示 */
.activity-icon {
    font-size: 24px;
}

/* 按鈕樣式 */
.activity-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    margin-left: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease-in-out;
}

.activity-button.completed {
    background-color: #22c55e;
    color: white;
}

.activity-button.not-completed {
    background-color: #e5e7eb;
}

/* 存錢筒固定在最後一個活動按鈕下方 */
.piggy-bank-container-fixed {
    display: flex;
    justify-content: center;
    margin-top: 16px; /* 與活動列表間距 */
    width: 100%;
}

/* 存錢筒區塊 */
.piggy-bank {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* 存錢筒表情 */
.piggy-icon {
    font-size: 48px;
}

/* 存錢數字 */
.piggy-count {
    position: absolute;
    top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #ea580c;
}
